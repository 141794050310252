import { template as template_6e448685ed134d8b966ae06cf3080e8c } from "@ember/template-compiler";
const FKControlMenuContainer = template_6e448685ed134d8b966ae06cf3080e8c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
