import { template as template_27d62f38a7bd4808a34a09e499419f64 } from "@ember/template-compiler";
const FKText = template_27d62f38a7bd4808a34a09e499419f64(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
