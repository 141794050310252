import { template as template_3ce0c787ec784eceb152fb61992adbef } from "@ember/template-compiler";
const WelcomeHeader = template_3ce0c787ec784eceb152fb61992adbef(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
