import { template as template_70ec64efb5cd40c2b995fea984dca9fd } from "@ember/template-compiler";
const FKLabel = template_70ec64efb5cd40c2b995fea984dca9fd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
