import { template as template_3e649b9b494340b5875210553f649e62 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3e649b9b494340b5875210553f649e62(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
